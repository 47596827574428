import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import "circular-std";
import "../styles/nav.scss"

class Layout extends React.Component {
    openNav() {
        document.getElementById("navOverlay").style.display = "block";
    }

    closeNav() {
        document.getElementById("navOverlay").style.display = "none";
    }

    render() {
        const { children } = this.props
        // const rootPath = `${__PATH_PREFIX__}/`

        return (
            <div style={{height: "100%", width: "100%"}}>
                <div id="navOverlay" class="nav-overlay">
                    <span class="nav-close" onClick={this.closeNav}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </span>
                    <div class="overlay-content">
                        <a href="https://github.com/batiyeh" target="__blank">github</a>
                        <a href="https://www.linkedin.com/in/brian-atiyeh/" target="__blank">linkedin</a>
                        <a href="https://twitter.com/BrianAtiyeh" target="__blank">twitter</a>
                        <a href="mailto:brian.atiyeh@gmail.com">email</a>
                    </div>
                </div>
                <div className="fixed-top" id="navigation">
                    <nav className="navbar navbar-expand-lg primary-nav container">
                        <Link className="nav-item nav-link pl-0 mr-4" to="/">brian atiyeh</Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation" onClick={this.openNav}>
                            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
                        </button>
                        <div className="collapse navbar-collapse" id="navbar">
                            <div className="navbar-nav align-items-center ml-auto">
                                {/* <Link className="nav-item nav-link mr-4" to="/blog">blog</Link> */}
                                <a className="nav-item nav-link mr-4" href="https://github.com/batiyeh" target="__blank">github</a>
                                <a className="nav-item nav-link mr-4" href="https://www.linkedin.com/in/brian-atiyeh/" target="__blank">linkedin</a>
                                <a className="nav-item nav-link mr-4" href="https://twitter.com/BrianAtiyeh" target="__blank">twitter</a>
                                <a href="mailto:brian.atiyeh@gmail.com" className="btn btn-layered offset square" role="button" aria-pressed="true">hire me</a>
                            </div>
                        </div>
                    </nav>
                </div>
                <main className="wrapper">{children}</main>
                    {/* <footer>
                        © {new Date().getFullYear()}, Built with
                        {` `}
                        <a href="https://www.gatsbyjs.org">Gatsby</a>
                    </footer> */}
            </div>
        )
    }
}

export default Layout
